import React, { useState, useEffect } from 'react';
import { ArrowUpRight, ArrowDownRight } from 'lucide-react';
import { getStocksData } from '../../api/be-client';
import './StocksTable.css';

const StocksTable = ({onStockClick}) => {
  const [allStockData, setAllStockData] = useState([]);
  const [displayedStocks, setDisplayedStocks] = useState([]);

  // Function to get 8 random stocks from the full dataset
  const getRandomStocks = (stocks) => {
    const shuffled = [...stocks].sort(() => 0.5 - Math.random());
    return shuffled.slice(0, 8);
  };

  const fetchStockData = async () => {
    try {
      const response = await getStocksData();
      if (response && response.tickers) {
        const formattedData = response.tickers.map(ticker => {
          const { day, prevDay } = ticker;
  
          // Check if current day data is valid
          const hasCurrentData = Object.values(day).some(value => value !== 0);
  
          const dataToUse = hasCurrentData ? day : prevDay;
  
          return {
            symbol: ticker.ticker,
            volume: dataToUse.v.toLocaleString(),
            price: dataToUse.c.toFixed(2),
            change: hasCurrentData ? ticker.todaysChangePerc.toFixed(2) : null, // Consider handling no-change scenario
          };
        });
        setAllStockData(formattedData);
      }
    } catch (error) {
      console.error('Error fetching stock data:', error);
    }
  };
  // const fetchStockData = async () => {
  //   try {
  //     const response = await getStocksData();
  //     if (response && response.tickers) {
  //       console.log(response.tickers);
  //       const formattedData = response.tickers.map(ticker => ({
  //         symbol: ticker.ticker,
  //         volume: ticker.day.v.toLocaleString(), // Volume added here
  //         price: ticker.day.c.toFixed(2),
  //         change: ticker.todaysChangePerc.toFixed(2)
  //       }));
  //       setAllStockData(formattedData);                
  //     }
  //   } catch (error) {
  //     console.error('Error fetching stock data:', error);
  //   }
  // };

  // Effect for initial data fetch
  useEffect(() => {
    fetchStockData();
  }, []);

  // Effect for setting up rotation and handling displayed stocks
  useEffect(() => {
    if (allStockData.length > 0) {
      setDisplayedStocks(getRandomStocks(allStockData));
      const rotateInterval = setInterval(() => {
        setDisplayedStocks(getRandomStocks(allStockData));
      }, 10000); // 10 sec

      return () => clearInterval(rotateInterval);
    }
  }, [allStockData]);

  return (
    <div className="stocks-table-container">
      <h2 className="stocks-table-title">U.S. Stocks</h2>
      <table className="stocks-table">
        <thead>
          <tr>
            <th>Symbol</th>
            <th>Volume</th>
            <th>Price</th>
            <th>Change</th>
          </tr>
        </thead>
        <tbody>
          {displayedStocks.map((stock) => (
            <tr key={stock.symbol}>
              <td onClick={()=>{onStockClick(stock.symbol)}} className="stock-symbol">{stock.symbol}</td>
              <td>${stock.volume}</td>
              <td className="stock-price">${stock.price}</td>
              <td className={`stock-change ${parseFloat(stock.change) >= 0 ? 'positive' : 'negative'}`}>
                {parseFloat(stock.change) >= 0 ? (
                  <ArrowUpRight className="change-icon" size={16} />
                ) : (
                  <ArrowDownRight className="change-icon" size={16} />
                )}
                {stock.change}%
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default StocksTable;
