import React, { useContext } from "react";
import "./SiteHeader.css";
import bosLogo from "../../assets/logo.png";
import { useLocation, useNavigate } from "react-router-dom";
import { appContext } from "../../App";

const Header = () => {
  const { userLogged, userName, setUserLogged } = useContext(appContext);

  const location = useLocation()
  console.log(location.pathname);
  const nav = useNavigate();
  const handleLogout = () => {
    setUserLogged(false);
    localStorage.removeItem("bos-token")
    nav("/login");
  };

  return (
    <div id="header-container">
      <div
        id="header-logo"
        onClick={() => {
          nav("/home");
        }}
      >
        <img id="header-logo-img" src={bosLogo} alt="" />
      </div>
      <div id="header-right-container">
         <div
          className="header-link"
          onClick={() => {
            nav("/home");
          }}
        >
          Home
        </div>
        <div
          className="header-link"
          onClick={() => {
            nav("/about");
          }}
        >
          About
        </div>
        <div
          className="header-link"
          onClick={() => {
            nav("/contact-us");
          }}
        >
          Contact-Us
        </div>
        <div
          className="header-link"
          onClick={() => {
            nav("/how-it-works");
          }}
        >
          How-It-Works
        </div>
        {!userLogged && (
          <div
            className="header-link"
            onClick={() => {
              nav("/login");
            }}
          >
            Sign-In
          </div>
        )}
        {userLogged && <div className="header-link"> Hello {userName} </div>}
        {userLogged && (
          <div className="header-link" onClick={handleLogout}>
            Logout
          </div>
        )}
      </div>
    </div>
  );
};

export default Header;