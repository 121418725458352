import React from 'react'
import { Route, Routes } from 'react-router-dom'
import Login from './components/login/Login'
import Home from './components/home/Home'
import About from './components/about/About'
import PostPayment from './components/postPayment/PostPayment'
import Careers from './components/careers/Careers'
import ApiPage from './components/apiPage/ApiPage'
import ContactUs from './components/contactUs/ContactUs'
import HowItWorks from './components/howItWorks/HowItWorks'

const SiteRoutes = () => {
  return (
      <Routes>
          <Route path='/' element={ <Home/>} /> 
          <Route path='/home' element={ <Home/>} /> 
          <Route path='/careers' element={ <Careers/>} /> 
          <Route path='/api-page' element={ <ApiPage/>} /> 
          <Route path='/about' element={ <About/>} /> 
          <Route path='/login' element={ <Login/>} />
          <Route path='/contact-us' element={ <ContactUs/>} />
          <Route path='/post-payment' element={ <PostPayment/>} />
          <Route path='/how-it-works' element={ <HowItWorks/>} />
          <Route path='*' element={<Home />} /> {/* Default route for unmatched paths */}
      </Routes>
  )
}

export default SiteRoutes