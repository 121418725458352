import React, { useState, useEffect } from "react";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  LogarithmicScale
} from 'chart.js';
import "./TechnicalAnalysisDemo.css";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  LogarithmicScale
);

const TechnicalAnalysisDemo = () => {
  const [data, setData] = useState({
    labels: [],
    datasets: []
  });

  useEffect(() => {
    const generateStockData = () => {
      const labels = [];
      const stockPrices = [];
      const movingAverage = [];
      const trendLine = [];

      for (let i = 1; i <= 30; i++) {
        labels.push(`Day ${i}`);
        stockPrices.push(Math.log(i + 1) * 40 + 100 + Math.random() * 200 );
        movingAverage.push(Math.log(i + 1) * 50 + 150);
        trendLine.push(Math.log(i + 1) * 50 + 55);
      }

      setData({
        labels: labels,
        datasets: [
          {
            label: "Stock Price",
            data: stockPrices,
            borderColor: "#6a5acd",
            borderWidth: 2,
            fill: false,
            pointRadius: 1,
            tension: 0.2,
          },
          {
            label: "Moving Average",
            data: movingAverage,
            borderColor: "#ff6347",
            borderWidth: 2,
            fill: false,
            pointRadius: 0,
            tension: 0.2,
          },
          {
            label: "Trend Line",
            data: trendLine,
            borderColor: "#32cd32",
            borderWidth: 2,
            fill: false,
            pointRadius: 0,
            tension: 0,
          }
        ],
      });
    };

    generateStockData();
  }, []);

  const options = {
    responsive: true,
    scales: {
      x: {
        display: true,
        title: {
          display: true,
          text: "Days",
        },
      },
      y: {
        display: true,
        title: {
          display: true,
          text: "Price (Logarithmic Scale)",
        },
        type: 'logarithmic',
      },
    },
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: 'Stock Price Analysis',
      },
    },
    animation: {
      duration: 0,
    },
  };

  return (
    <div className="graph-container">
      <div className="chart-frame">
      
        <Line data={data} options={options} />
        <div className="animated-overlay"></div>
      </div>
    </div>
  );
};

export default TechnicalAnalysisDemo;