// LoginWelcome.jsx
import React from 'react';
import './LoginWelcome.css';

const LoginWelcome = () => {
  return (
    <div className="login-welcome-container">
      <h1>Gain Real-Time Analysis and Stock Market Data</h1>
      <p className="subtitle">Get exclusive insights and data-driven analysis to make smarter investment decisions.</p>
      <p className="description">
        At <span className="brand">BUYORSELL</span>, we help you make informed choices using real-time technical analysis for any U.S. stock market listing.
      </p>
      <div className="features">
        <div className="feature">
          <h3>Real-Time Insights</h3>
          <p>Stay updated on market trends.</p>
        </div>
        <div className="feature">
          <h3>Personalized Analysis</h3>
          <p>Receive tailored recommendations.</p>
        </div>
        <div className="feature">
          <h3>Portfolio Tracking</h3>
          <p>Manage and optimize your investments with ease.</p>
        </div>
      </div>
      <p className="cta">Sign up today and start investing smarter!</p>
    </div>
  );
};

export default LoginWelcome;
