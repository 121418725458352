import React, { useContext, useEffect, useState } from 'react';
import "./Home.css";
import { stocksData } from '../../assets/stockData';
import { appContext } from '../../App';
import { useNavigate } from 'react-router-dom';
import PaypalArea from '../paypalArea/PaypalArea';
import StocksTable from '../stocksTable/StocksTable';
import TechnicalAnalysisDemo from '../technicalAnalysisDemo/TechnicalAnalysisDemo';
import LoginRedirector from '../loginRedirector/LoginRedirector';

const Home = () => {
    const [searchTerm, setSearchTerm] = useState("");
    const [filteredStocks, setFilteredStocks] = useState(stocksData);
    const [selectedStock, setSelectedStock] = useState(null);
    const [showPPmodal, setShowPPmodal] = useState(false);
    const [showLoginRedirector, setShowLoginRedirector] = useState(false);
    const nav = useNavigate();
    const { userLogged } = useContext(appContext);

    const handleSearchChange = (e) => {
        const term = e.target.value.toLowerCase();
        setSearchTerm(term);
        setSelectedStock(null);
        setFilteredStocks(
            Object.values(stocksData).filter(stock =>
                stock.name.toLowerCase().includes(term) ||
                stock.symbol.toLowerCase().includes(term)
            )
        );
    };
    const handleTableSelect = (symbol) => {
        const stockArray = Object.values(stocksData); // Convert object to array
        const selectedStock = stockArray.find(stock => stock.symbol.toLowerCase() === symbol.toLowerCase());
        
        // const selectedStock = stocksData.find(stock => stock.symbol.toLowerCase() === symbol.toLowerCase());
        if (selectedStock) {
          setSelectedStock(selectedStock);
          setSearchTerm(""); // Clear search term after selecting from table
        }
      };
    
    const handleStockSelect = (stock) => {
        setSearchTerm("");
        setSelectedStock(stock);
    };

    const handleRequest = async () => {

        if (!userLogged) {
            console.log("yu");
            
            setShowLoginRedirector(true);
            console.log("yu");
            return;
            // nav("/login");
        }
        setShowPPmodal(true);       
    };

    return (
        <div className="home-container">
            {showLoginRedirector && <LoginRedirector onClose={()=>{setShowLoginRedirector(false)}} show={showLoginRedirector} />}
            <h2>Welcome to BUY OR SELL </h2>
            <h4>
                The leading platform for stocks analysis
                Quick, professional and reliable
                want to learn more? Click here
            </h4>
            <p>Search by Stock Symbol or Company Name</p>
            <input
                type="text"
                placeholder="Search for a stock..."
                value={searchTerm}
                onChange={handleSearchChange}
                className="stock-search-input"
            />
            {selectedStock && (
                <div className="stock-details">
                    <h2>{selectedStock.symbol}</h2>
                    <h3>{selectedStock.name}</h3>
                    {selectedStock.sector.length > 0 && <p><strong>Sector:</strong> {selectedStock.sector}</p>}
                    {selectedStock.country.length > 0 && <p><strong>Country:</strong> {selectedStock.country}</p>}
                    {selectedStock.industry.length > 0 && <p><strong>Industry:</strong> {selectedStock.industry}</p>}
                    <button className="analysis-button" onClick={handleRequest}>Request for Analysis</button>
                </div>
            )}

            {/* {searchTerm.length ===0 && <StocksTable/>} */}
            {(selectedStock === null && searchTerm.length === 0) && <>
                {/* <TechnicalAnalysisDemo /> */}
                <StocksTable onStockClick={ (term)=>handleTableSelect(term)} />
            </>}
            <div className="stock-list">
                {searchTerm.length > 0 && filteredStocks.map((stock) => (
                    <button
                        key={stock.symbol}
                        className="stock-button"
                        onClick={() => handleStockSelect(stock)}
                    >
                        {stock.name} ({stock.symbol})
                    </button>
                ))}
            </div>
            <PaypalArea showModal={showPPmodal} setShowModal={setShowPPmodal} selectedStock={selectedStock} />
        </div>
    );
};

export default Home;