import { SERVER_URL } from "./config";
import axios from "axios";

export async function sendRequest(symbol) {
    try {
        const token = localStorage.getItem("bos-token")
        const url = SERVER_URL + "/new-ar";
        const res = await axios.post(url, { symbol }, {
            headers: {
                Authorization: `Token ${token}`
            }
        });
        return res.status === 200;
    } catch (error) {
        console.error('Error sending AR:', error);
        return false;
    }
}
export async function sendContact(content) {
    try {
        const token = localStorage.getItem("bos-token")
        const url = SERVER_URL + "/contact-us";
        const res = await axios.post(url, { content }, {
            headers: {
                Authorization: `Token ${token}`
            }
        });
        return res.status === 200;
    } catch (error) {
        console.error('Error sending contact-us:', error);
        return false;
    }
}

export async function getStocksData() {
    const url = SERVER_URL + "/stocks-prices";
    try {
        const res = await fetch(url);
        const resJ = await res.json();
        return resJ;
    } catch (error) {
        alert("Error fetching data");
        return false;
    }
}