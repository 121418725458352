import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import './LoginRedirector.css';

const LoginRedirector = ({ show, onClose }) => {
  const navigate = useNavigate();

  const handleContinueToLogin = () => {
    navigate('/login');
  };

  return (
    <Modal show={show} onHide={onClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>Login Required</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>To access this feature, please log in or register.</p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onClose} className="modal-cancel-button">
          Cancel
        </Button>
        <Button variant="primary" onClick={handleContinueToLogin} className="modal-login-button">
          Continue to Login
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default LoginRedirector;
