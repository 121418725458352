import React from 'react';
import { Link } from 'react-router-dom';
import './SiteFooter.css';

const SiteFooter = () => {
  return (
    <footer className="site-footer">
      <div className="footer-content">
        <div className="footer-logo">
          {/* <h2>CodeGPT</h2> */}
        </div>
        <nav className="footer-nav">
          <ul>
            <li><Link to="/home">Home</Link></li>
            <li><Link to="/about">About</Link></li>
            <li><Link to="/careers">Careers</Link></li>
            <li><Link to="/contact-us">Contact-Us</Link></li>
            <li><Link to="/how-it-works">How-It-Works</Link></li>
            <li><Link to="/api-page">API</Link></li>            
          </ul>
        </nav>
        <div className="footer-bottom">
          <p>&copy; 2024 BuyOrSell. All rights reserved.</p>
        </div>
      </div>
    </footer>
  );
};

export default SiteFooter;