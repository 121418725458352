import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import "./ContactUs.css"
import { sendContact } from '../../api/be-client';


const ContactUs = () => {
    const [content, setContent] = useState('');
    const [error, setError] = useState('');
    const nav = useNavigate();

    const handleSubmit = (e) => {
        e.preventDefault();
        if (!content.trim()) {
            setError('Message content cannot be empty');
            return;
        }
        // Call the method to send the message
        sendContact(content)
            .then(() => {
                alert('Message sent');
                nav('/home');
            })
            .catch(() => {
                setError('Failed to send message. Try again.');
            });
    };

    return (
        <div className="contact-container">
            <h1>Contact Us</h1>
            <form onSubmit={handleSubmit} className="contact-form">
                <textarea
                    placeholder="Enter your message..."
                    value={content}
                    onChange={(e) => setContent(e.target.value)}
                    className="contact-input"
                />
                {error && <p className="error-message">{error}</p>}
                <button type="submit" className="contact-btn">Send Message</button>
            </form>
        </div>
    );
};

export default ContactUs;
