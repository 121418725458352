import React from 'react';
import './About.css';
import { useNavigate } from 'react-router-dom';
import HowItWorks from '../howItWorks/HowItWorks';

const About = () => {

  const nav = useNavigate()
  return (
    <div className="about-container">
      <h1>About Us</h1>
      <p>
        Are you considering whether you should buy or sell Stocks?
        Get a Technical Analysis Before Every Decision!
        In the stock market, making informed decisions is the key to success.
        But how would you know if it’s the right time to buy or sell a stock?

        That’s where BUYORSELL comes in – a platform that connects traders with professional technical analysts, providing real-time, reliable graphical and technical analyses for any chosen stock in the U.S.  stock market.

      </p>

      <h2 style={{ textAlign: "center" }}>Our Services</h2>
      <p>
        We offer technical stock analysis.
        On our platform, you can search for any U.S. market stock and receive a detailed analysis within 12 hours, helping you understand market trends, get another perspective and make informed investment decisions.

      </p>
      <p>
        We are the premier platform connecting you with top industry experts, providing an extensive range of analytics such as trendlines, support and resistance levels, moving averages, chart patterns, and more.
      </p>
      <h2 style={{ textAlign: "center" }}>Our Experts</h2>
      <p>
        At BUYORSELL, we work with experienced technical analysts, each with over 10 years of experience in the stock market. Their expertise in market trends and chart patterns ensures you receive accurate, actionable insights, to guide your investment choices.
      </p>
      <br/>
      <br/>
      <button className='repeat-btn' onClick={() => { nav("/home") }}>I want get a stock analysis </button>
      <br/>
      <br />
      <h2 style={{ textAlign: "center" }}>Our Advantages</h2>      
        <ul className='advantage-ul'>
          <li className='advantage-li'>Save Time: Streamline your decision-making process and make choices with confidence.</li>
          <li className='advantage-li'>Qualified Analysis: Whether you are a professional trader or a beginner, by choosing BUYORSELL, you receive expert analysis tailored to your needs.</li>
          <li className='advantage-li'>Expert Guidance: Work alongside experienced analysts with extensive experience in the stock market, ensuring that you receive personalized advice and insights that align with your investment goals.</li>
          <li className='advantage-li'> Actionable Recommendations: Benefit from insights that not only inform but also guide your investment strategies, allowing you to navigate the market with clarity and confidence.</li>
        </ul>            
      <br/>
      <h5>
      Join us today and make confident, informed investment decisions backed by expert insights and in-depth analysis.
      </h5>
      <br/>
      <button className='repeat-btn' onClick={() => { nav("/home") }}>I want get a stock analysis </button>
      <br/>
      <br />
      <HowItWorks/>
      <button className='repeat-btn' onClick={() => { nav("/home") }}>I want get a stock analysis </button>
    </div>
  );
};

export default About;
