import React from 'react';
import './HowItWorks.css';

const HowItWorks = () => {
  return (
    <div className="how-it-works">
      <h1>How It Works</h1>
      <div className="steps">
        <div className="step">
          <h2>Register</h2>
          <p>Create an account with us to get started. It’s quick and easy!</p>
        </div>
        <div className="step">
          <h2>Choose a Stock</h2>
          <p>Select the stock you want to analyze from the U.S. stock market.</p>
        </div>
        <div className="step">
          <h2>Request an Analysis</h2>
          <p>
            You can request an in-depth technical analysis of your selected stock. We collaborate with top analysts from around the globe to ensure you receive expert reports.
          </p>
        </div>
        <div className="step">
          <h2>Pay</h2>
          <p>
          Proceed with payment to confirm your analysis request (PayPal or Credit card)
          </p>
        </div>
        <div className="step">
          <h2>Get Your Analysis</h2>
          <p>
          Within 12 hours, you’ll receive a detailed PDF report to your email. 
          </p>
        </div>
      </div>
    </div>
  );
};

export default HowItWorks;
