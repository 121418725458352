import { createContext, useEffect, useState } from "react";
import SiteRoutes from "./SiteRoutes";
import { isTokenValid } from "./api/authApi";
import SiteHeader from "./components/siteHeader/SiteHeader";
import SiteFooter from "./components/siteFooter/SiteFooter";
import { useNavigate } from "react-router-dom";
import PaypalArea from "./components/paypalArea/PaypalArea";
import TechnicalAnalysisDemo from "./components/technicalAnalysisDemo/TechnicalAnalysisDemo";


export const appContext = createContext(null);

function App() {
  const [userLogged, setUserLogged] = useState(false);
  const [userName, setUserName] = useState("");

  const nav = useNavigate();

  useEffect(() => {
    // check if user "already connected" - if token exists in localStorage
    const token = localStorage.getItem("bos-token");
    if (token) {

      isTokenValid(token).then((res) => {
        if (res) {
          setUserLogged(true);
          setUserName(localStorage.getItem("bos-username"));
          nav("/home")
        } else {
          localStorage.removeItem("token");
        }
      })
    }
  }, []);

  return (
    <div>      

      <div id="site-wrapper">
        <appContext.Provider
          value={{ userLogged, setUserLogged, userName, setUserName }}
        >
          <SiteHeader />
          <div id="site-routes-wrapper">
            <SiteRoutes />
          </div>
        </appContext.Provider>
        <br />
        <br />
      </div>
      <div>
        <SiteFooter />
      </div>
    </div>
  );
}

export default App;